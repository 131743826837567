import React from "react";

import PageTitle from "../_App/PageTitle";
import ClientsSlider from "../Sections/ClientsSlider";

//images
import background from "./../../assets/images/background/background_2.webp";
import banner from "../../assets/images/gallery/welding/welding_1.webp";

const Welding = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Zavarivanje" parentPage="Naslovna" />

        <section className="content-inner-1 section" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "top" }}>
          <div className="container">
            <div className="row about-style14">
              <div className="col-lg-6 m-b30">
                <div className="dz-media overlay-white-light">
                  <img src={banner} alt="Welding" width="1766" height="1179" />
                </div>
              </div>
              <div className="col-lg-6 m-b30 align-self-center">
                <div className="about-content">
                  <div className="section-head style-1">
                    <h2 className="title m-b20">
                      Zavarivanje
                    </h2>
                  </div>
                  <div className="info">
                    <p className="m-b30">
                      Debljine materijala laserskog zavarivanja se kreću za ugljenični čelik od <b>0.5 mm</b> do <b>4 mm</b>, za prohrom  od <b>0.5 mm</b> do <b>4 mm</b>, 
                      a za aluminijum od <b>0.5 mm </b> do <b>3 mm</b>.
                    </p>
                  </div>
                </div>
              </div>
              <div className="info">
                <p className="m-b30">
                  <b>CO<sub>2</sub> zavarivanje (MIG / MAG)</b>, ovim gasnim postupkom zavarivanja stvara se nerazdvojiva veza primenom toplote ostvarene sagorevanjem gorivog gasa, 
                  pri čemu se rastapa samo osnovni ili osnovni i dodatni materijal.
                </p>
                <p className="m-b30">
                  Pored CO<sub>2</sub>, naša kompanija raspolaže i tehnologijom <b>laserskog zavarivanja</b>. U 2023. godini opremili smo naš zavarivački sektor SENFENG mašinom za 
                  lasersko zavarivanje jačine SF2000HWM 2 kW sa sistemom za automatsko dovođenje žice.
                </p>
              </div>
            </div>
          </div>

          <div className="container content-inner-2">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default Welding;