import React from "react";
import ReactDOM from "react-dom/client";

import { LightgalleryProvider } from "react-lightgallery";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Global Styles
import "./assets/css/style.css";

import "swiper/css";
import "react-modal-video/css/modal-video.min.css";
import "lightgallery.js/dist/css/lightgallery.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <LightgalleryProvider>
      <App />
    </LightgalleryProvider>
  </React.StrictMode>
);

reportWebVitals();