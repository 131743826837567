import React from "react";

import PageTitle from "../_App/PageTitle";
import ClientsSlider from "../Sections/ClientsSlider";

//images
import background from "./../../assets/images/background/background_2.webp";
import banner from "../../assets/images/gallery/powder_coating/powder_coating_1.webp";

const PowderCoating = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Plastifikacija" parentPage="Naslovna" />

        <section className="content-inner-1 section" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "top" }}>
          <div className="container">
            <div className="row about-style14">
              <div className="col-lg-6 m-b30">
                <div className="dz-media overlay-white-light">
                  <img src={banner} alt="Powder Coating Machine" width="1000" height="750" />
                </div>
              </div>
              <div className="col-lg-6 m-b30 align-self-center">
                <div className="about-content">
                  <div className="section-head style-1">
                    <h2 className="title m-b20">
                      Plastifikacija
                    </h2>
                  </div>
                  <div className="info">
                    <p className="m-b30">
                      Plastificiranje metala sa raspoloživim kapacitetom od <b>6.8 m</b> dužine, <b>1.2 m</b> širine i <b>2.2 m</b> visine komada.
                    </p>
                  </div>
                </div>
              </div>
              <div className="info">
                <p className="m-b30">
                  Početkom 2024. godine odlučili smo da upotpunimo naš portfolio usluga površinskog tretmana ulaganjem u opremu za plastifikaciju. Opremili smo 
                  naš pogon najsavremenijom opremom za plastificiranje metala firme ProPlastik IN.
                </p>
              </div>
            </div>
          </div>

          <div className="container content-inner-2">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default PowderCoating;