import React from "react";

import PageTitle from "../_App/PageTitle";
import ClientsSlider from "../Sections/ClientsSlider";

//images
import background from "./../../assets/images/background/background_2.webp";
import banner from "../../assets/images/gallery/laser_cutting/laser_cutting_1.webp";

const LaserCutting = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Lasersko Sečenje" parentPage="Naslovna" />

        <section className="content-inner-1 section" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "top" }}>
          <div className="container">
            <div className="row about-style14">
              <div className="col-lg-6 m-b30">
                <div className="dz-media overlay-white-light">
                  <img src={banner} alt="Laser Cutting Machine" width="1831" height="1760" />
                </div>
              </div>
              <div className="col-lg-6 m-b30 align-self-center">
                <div className="about-content">
                  <div className="section-head style-1">
                    <h2 className="title m-b20">
                      Lasersko Sečenje
                    </h2>
                  </div>
                  <div className="info">
                    <p className="m-b30">
                      <b>Sečenje Lima:</b> <br></br>
                      Ova usluga obuhvata tehnologiju laserskog sečenja čelika, nerđajućeg čelika i pocinkovanog čelika različitih debljina (do <b>30 mm</b>)
                      s preciznošću do <b>0,1 mm</b>.
                    </p>
                  </div>
                  <div className="info">
                    <p className="m-b30">
                      <b>Sečenje Cevi:</b> <br></br>
                      Raspon cevi koje možemo seći kreće se od <b>Ø20 mm</b> do <b>Ø220 mm</b> za okrugle cevi, od <b>20x20 mm</b> do <b>150x150 mm</b> za
                      kvadratne cevi, i od <b>30 mm</b> do <b>220 mm</b> za pravougaone cevi sa dijagonalama, sa maksimalnom debljinom zida cevi do <b>10 mm</b>.
                    </p>
                  </div>
                </div>
              </div>
              <div className="info">
                <p className="m-b30">
                  Lasersko sečenje metala je usluga koju naše preduzeće uspešno pruža od 2014. godine. Ova usluga značajno smanjuje troškove proizvodnje i
                  eliminiše potrebu za skladištenjem među pojedinim fazama. Takođe, dodatni radovi poput čišćenja proizvoda više nisu potrebni. Osim toga,
                  izborom odgovarajuće metode laserskog sečenja često se smanjuje i broj sastavnih delova.
                </p>
              </div>
            </div>
          </div>

          <div className="container content-inner-2">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default LaserCutting;