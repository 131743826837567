import React from "react";

import VideoPopup from '../_App/VideoPopup';

//images
import background from "./../../assets/images/background/background_9.webp";
import banner from './../../assets/images/banner/banner_5.webp';

const Infrastructure = () => {
  return (
    <>
      <section id="infrastructure" className="content-inner section bg-secondary" style={{ backgroundImage: "url(" + background + ")", backgroundPosition: "center", backgroundSize: "cover" }}>
        <div className="container">
          <div className="row about-style6">
            <div className="col-lg-6 m-b30 align-self-center aos-item" data-aos="fade-right" data-aos-duration="800" data-aos-delay="500">
              <div className="about-content">
                <div className="section-head style-1">
                  <h5 className="sub-title text-primary">
                    INFRASTRUKTURA
                  </h5>
                  <h3 className="title m-b20">
                    Pogonski Kapaciteti i Mašine
                  </h3>
                  <p>
                    Preduzeće je opremljeno mašinama, opremom i alatima neophodnim za uspešno obavljanje usluga sečenja, 
                    savijanja i zavarivanja, kao i farbanja i montaže. Posebno se izdvajaju laseri, gas, plazma za sečenje 
                    limova, prese za saviijanje i trovaljak za savijanje, testere za sečenje cevi i profila. Uz to, u svom 
                    pogonu posedujemo dobro opremljenu bravarsku radionicu i zasebno odvojenu komoru za farbanje.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 m-b70 align-self-end">
              <div className="about-media aos-item">
                <div className="dz-media m-b40">
                  <img className="w-100" style={{ border: "7px solid var(--primary)" }} src={banner} alt="thumbnail" width="700" height="700" />
                  <VideoPopup classChange="popup-youtube play-btn2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Infrastructure;