import React from "react";

//images
import background from "./../../assets/images/background/background_3.webp";
import banner from './../../assets/images/banner/banner_2.webp';

const RequestQuote = () => {

  return (
    <>
      <section id="request-quote" className="page-content bg-white">
        <div className="content-inner bg-gray overlay-black-dark" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "center" }}>
          <div className="container">
            <div className="row about-style8 text-white">
              <div className="col-lg-6 m-b30 align-self-center aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">
                <div className="about-content">
                  <div className="section-head style-1">
                    <h5 className="sub-title text-primary">
                      HEFEOS
                    </h5>
                    <h3 className="title m-b20 text-white">
                      Automatsko Online Naručivanje
                    </h3>
                    <p>
                      Olakšajte sebi kupovinu i uštedite dragoceno vreme pri naručivanju. Naručite online!
                      Naša platforma ne samo da vam omogućava brzo i jednostavno poručivanje, već koristi i naprednu AI 
                      tehnologiju kako bi vam olakšala kupovinu, pružila još bolje iskustvo, kao i preciznije cene izrade.
                    </p>
                  </div>
                  <a className="btn btn-primary btn-border btn-border-white m-r10 m-b10" href="https://pavic.hefeos.com/register">
                    OTVORITE NALOG
                  </a>
                </div>
              </div>

              <div className="col-lg-6 m-b30">
                <div className="dz-media">
                  <div className="split-box">
                    <img className="aos-item" src={banner} alt="HEFEOS" width="600" height="482" />
                  </div>
                  <ul className="list-check-circle white aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="1000">
                    <li>
                      Instant Ponuda Cene
                    </li>
                    <li>
                      Više Fajlova Istovremeno
                    </li>
                    <li>
                      Podrška za DXF i STEP Fajlove
                    </li>
                    <li>
                      Datum koji Vam Najviše Odgovara
                    </li>
                    <li>
                      Garantovan Kvalitet
                    </li>
                    <li>
                      Neprekidna 24/7 Inženjerska Podrška
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RequestQuote;