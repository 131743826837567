import React from "react";

import PageTitle from "../_App/PageTitle";
import ClientsSlider from "../Sections/ClientsSlider";

//images
import background from "./../../assets/images/background/background_2.webp";
import banner from "../../assets/images/gallery/painting/painting_1.webp";

const Painting = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Farbanje" parentPage="Naslovna" />

        <section className="content-inner-1 section" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "top" }}>
          <div className="container">
            <div className="row about-style14">
              <div className="col-lg-6 m-b30">
                <div className="dz-media overlay-white-light">
                  <img src={banner} alt="Painting" width="1696" height="1183" />
                </div>
              </div>
              <div className="col-lg-6 m-b30 align-self-center">
                <div className="about-content">
                  <div className="section-head style-1">
                    <h2 className="title m-b20">
                      Farbanje
                    </h2>
                  </div>
                  <div className="info">
                    <p className="m-b30">
                      Zahtevi naših kupaca za peskarenjem podstakli su nas da napravimo korak napred u tehnologiji čišćenja metalnih površina opremanjem laser mašinom za 
                      čišćenje metalnih površina. Raspolažemo SENFENG <b>laser mašinom za čišćenje</b> SF1500HC snage 1.5 kW, koja omogućava efikasno i precizno uklanjanje nečistoća.
                    </p>
                  </div>
                </div>
              </div>
              <div className="info">
                <p className="m-b30">
                  Metalne površine koje nisu zaštićene izložene su dejstvu vlage i vazduha, što izaziva pojavu korozije. Proces korozije se ubrzava prisustvom kiselina, baza, 
                  kiselih oksida i drugih hemikalija. Dejstvom korozije metalni predmeti se oštećuju površinski ili u celosti, fizičko-hemijskim putem. Brzina napredovanja korozije 
                  zavisi od mnogih faktora, kao što su koncentracija, vrsta i dužina dejstva korozionih agenasa, relativna vlažnost vazduha, temperatura i drugi uslovi.
                </p>
                <p className="m-b30">
                  Koroziji su podložni svi neplemeniti metali i njihove legure. Naša komora za farbanje, koja se nalazi u proizvodnom pogonu, zajedno sa iskusnim radnicima, efikasno 
                  rešava sve vaše probleme vezane za zaštitu metala od korozije.
                </p>
              </div>
            </div>
          </div>

          <div className="container content-inner-2">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default Painting;