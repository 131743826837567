import React from "react";

import { Link } from "react-router-dom";

//images
import background from "./../assets/images/background/background_1.webp";

const NotFound = () => {
  return (
    <>
      <div className="error-page overlay-black-light" style={{ backgroundImage: "url(" + background + ")" }}>
        <div className="error-inner text-center">
          <div className="dz_error" data-text="404">
            404
          </div>
          <h2 className="error-head">
            Nažalost, stranica koju tražite ne može biti pronađena.
          </h2>
          <Link to="/" className="btn btn-primary btn-border white-border">
            NAZAD NA NASLOVNU
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;