import React from "react";
import Masonry from "react-responsive-masonry";

import { Tab, Nav } from 'react-bootstrap';
import { ResponsiveMasonry } from "react-responsive-masonry";
import { LightgalleryItem } from "react-lightgallery";

import PageTitle from "./_App/PageTitle";
import ClientsSlider from "./Sections/ClientsSlider";

//images
import laserCutting1 from "./../assets/images/gallery/laser_cutting/laser_cutting_1.webp";
import laserCutting2 from "./../assets/images/gallery/laser_cutting/laser_cutting_2.webp";

import welding1 from "./../assets/images/gallery/welding/welding_1.webp";
import welding2 from "./../assets/images/gallery/welding/welding_2.webp";
import welding3 from "./../assets/images/gallery/welding/welding_3.webp";
import welding4 from "./../assets/images/gallery/welding/welding_4.webp";
import welding5 from "./../assets/images/gallery/welding/welding_5.webp";
import welding6 from "./../assets/images/gallery/welding/welding_6.webp";
import welding7 from "./../assets/images/gallery/welding/welding_7.webp";
import welding8 from "./../assets/images/gallery/welding/welding_8.webp";
import welding9 from "./../assets/images/gallery/welding/welding_9.webp";
import welding10 from "./../assets/images/gallery/welding/welding_10.webp";
import welding11 from "./../assets/images/gallery/welding/welding_11.webp";
import welding12 from "./../assets/images/gallery/welding/welding_12.webp";
import welding13 from "./../assets/images/gallery/welding/welding_13.webp";
import welding14 from "./../assets/images/gallery/welding/welding_14.webp";
import welding15 from "./../assets/images/gallery/welding/welding_15.webp";
import welding16 from "./../assets/images/gallery/welding/welding_16.webp";
import welding17 from "./../assets/images/gallery/welding/welding_17.webp";
import welding18 from "./../assets/images/gallery/welding/welding_18.webp";
import welding19 from "./../assets/images/gallery/welding/welding_19.webp";
import welding20 from "./../assets/images/gallery/welding/welding_20.webp";
import welding21 from "./../assets/images/gallery/welding/welding_21.webp";
import welding22 from "./../assets/images/gallery/welding/welding_22.webp";
import welding23 from "./../assets/images/gallery/welding/welding_23.webp";
import welding24 from "./../assets/images/gallery/welding/welding_24.webp";
import welding25 from "./../assets/images/gallery/welding/welding_25.webp";
import welding26 from "./../assets/images/gallery/welding/welding_26.webp";
import welding27 from "./../assets/images/gallery/welding/welding_27.webp";
import welding28 from "./../assets/images/gallery/welding/welding_28.webp";
import welding29 from "./../assets/images/gallery/welding/welding_29.webp";
import welding30 from "./../assets/images/gallery/welding/welding_30.webp";
import welding31 from "./../assets/images/gallery/welding/welding_31.webp";
import welding32 from "./../assets/images/gallery/welding/welding_32.webp";
import welding33 from "./../assets/images/gallery/welding/welding_33.webp";
import welding34 from "./../assets/images/gallery/welding/welding_34.webp";
import welding35 from "./../assets/images/gallery/welding/welding_35.webp";
import welding36 from "./../assets/images/gallery/welding/welding_36.webp";
import welding37 from "./../assets/images/gallery/welding/welding_37.webp";

import painting1 from "./../assets/images/gallery/painting/painting_1.webp";
import painting2 from "./../assets/images/gallery/painting/painting_2.webp";
import painting3 from "./../assets/images/gallery/painting/painting_3.webp";
import painting4 from "./../assets/images/gallery/painting/painting_4.webp";
import painting5 from "./../assets/images/gallery/painting/painting_5.webp";
import painting6 from "./../assets/images/gallery/painting/painting_6.webp";
import painting7 from "./../assets/images/gallery/painting/painting_7.webp";
import painting8 from "./../assets/images/gallery/painting/painting_8.webp";
import painting9 from "./../assets/images/gallery/painting/painting_9.webp";
import painting10 from "./../assets/images/gallery/painting/painting_10.webp";
import painting11 from "./../assets/images/gallery/painting/painting_11.webp";
import painting12 from "./../assets/images/gallery/painting/painting_12.webp";
import painting13 from "./../assets/images/gallery/painting/painting_13.webp";

import powderCoating1 from "./../assets/images/gallery/powder_coating/powder_coating_1.webp";
import powderCoating2 from "./../assets/images/gallery/powder_coating/powder_coating_2.webp";
import powderCoating3 from "./../assets/images/gallery/powder_coating/powder_coating_3.webp";
import powderCoating4 from "./../assets/images/gallery/powder_coating/powder_coating_4.webp";
import powderCoating5 from "./../assets/images/gallery/powder_coating/powder_coating_5.webp";

import bending1 from "./../assets/images/gallery/bending/bending_1.webp";
import bending2 from "./../assets/images/gallery/bending/bending_2.webp";

import manufactureOfMachineAssemblies1 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_1.webp";
import manufactureOfMachineAssemblies2 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_2.webp";
import manufactureOfMachineAssemblies3 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_3.webp";
import manufactureOfMachineAssemblies4 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_4.webp";
import manufactureOfMachineAssemblies5 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_5.webp";
import manufactureOfMachineAssemblies6 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_6.webp";
import manufactureOfMachineAssemblies7 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_7.webp";
import manufactureOfMachineAssemblies8 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_8.webp";
import manufactureOfMachineAssemblies9 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_9.webp";
import manufactureOfMachineAssemblies10 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_10.webp";
import manufactureOfMachineAssemblies11 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_11.webp";
import manufactureOfMachineAssemblies12 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_12.webp";
import manufactureOfMachineAssemblies13 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_13.webp";
import manufactureOfMachineAssemblies14 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_14.webp";
import manufactureOfMachineAssemblies15 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_15.webp";
import manufactureOfMachineAssemblies16 from "./../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_16.webp";


var counter = 0;

const images = {
  laserCutting: [ 
    { image: laserCutting1, index: counter++ },
    { image: laserCutting2, index: counter++ },
  ],
  welding: [ 
    { image: welding1, index: counter++ },
    { image: welding2, index: counter++ },
    { image: welding3, index: counter++ },
    { image: welding4, index: counter++ },
    { image: welding5, index: counter++ },
    { image: welding6, index: counter++ },
    { image: welding7, index: counter++ },
    { image: welding8, index: counter++ },
    { image: welding9, index: counter++ },
    { image: welding10, index: counter++ },
    { image: welding11, index: counter++ },
    { image: welding12, index: counter++ },
    { image: welding13, index: counter++ },
    { image: welding14, index: counter++ },
    { image: welding15, index: counter++ },
    { image: welding16, index: counter++ },
    { image: welding17, index: counter++ },
    { image: welding18, index: counter++ },
    { image: welding19, index: counter++ },
    { image: welding20, index: counter++ },
    { image: welding21, index: counter++ },
    { image: welding22, index: counter++ },
    { image: welding23, index: counter++ },
    { image: welding24, index: counter++ },
    { image: welding25, index: counter++ },
    { image: welding26, index: counter++ },
    { image: welding27, index: counter++ },
    { image: welding28, index: counter++ },
    { image: welding29, index: counter++ },
    { image: welding30, index: counter++ },
    { image: welding31, index: counter++ },
    { image: welding32, index: counter++ },
    { image: welding33, index: counter++ },
    { image: welding34, index: counter++ },
    { image: welding35, index: counter++ },
    { image: welding36, index: counter++ },
    { image: welding37, index: counter++ },
  ],
  painting: [ 
    { image: painting1, index: counter++ },
    { image: painting2, index: counter++ },
    { image: painting3, index: counter++ },
    { image: painting4, index: counter++ },
    { image: painting5, index: counter++ },
    { image: painting6, index: counter++ },
    { image: painting7, index: counter++ },
    { image: painting8, index: counter++ },
    { image: painting9, index: counter++ },
    { image: painting10, index: counter++ },
    { image: painting11, index: counter++ },
    { image: painting12, index: counter++ },
    { image: painting13, index: counter++ },
  ],
  powderCoating: [ 
    { image: powderCoating1, index: counter++ },
    { image: powderCoating2, index: counter++ },
    { image: powderCoating3, index: counter++ },
    { image: powderCoating4, index: counter++ },
    { image: powderCoating5, index: counter++ },
  ],
  bending: [ 
    { image: bending1, index: counter++ },
    { image: bending2, index: counter++ },
  ],
  manufactureOfMachineAssemblies: [ 
    { image: manufactureOfMachineAssemblies1, index: counter++ },
    { image: manufactureOfMachineAssemblies2, index: counter++ }, 
    { image: manufactureOfMachineAssemblies3, index: counter++ }, 
    { image: manufactureOfMachineAssemblies4, index: counter++ }, 
    { image: manufactureOfMachineAssemblies5, index: counter++ }, 
    { image: manufactureOfMachineAssemblies6, index: counter++ }, 
    { image: manufactureOfMachineAssemblies7, index: counter++ }, 
    { image: manufactureOfMachineAssemblies8, index: counter++ }, 
    { image: manufactureOfMachineAssemblies9, index: counter++ }, 
    { image: manufactureOfMachineAssemblies10, index: counter++ }, 
    { image: manufactureOfMachineAssemblies11, index: counter++ }, 
    { image: manufactureOfMachineAssemblies12, index: counter++ }, 
    { image: manufactureOfMachineAssemblies13, index: counter++ }, 
    { image: manufactureOfMachineAssemblies14, index: counter++ }, 
    { image: manufactureOfMachineAssemblies15, index: counter++ }, 
    { image: manufactureOfMachineAssemblies16, index: counter++ }, 
  ],
};

const Gallery = () => {

  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Galerija" parentPage="Naslovna" />

        <section className="content-inner">
          <div className="container">
            <Tab.Container defaultActiveKey="all">
              <div className="site-filters style-1 clearfix center mb-5">
                <Nav as="ul" className="filters" data-toggle="buttons">
                  <Nav.Item as="li" className="btn">
                    <input type="radio" />
                    <Nav.Link eventKey="all">
                      Sve Fotografije
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="btn">
                    <input type="radio" />
                    <Nav.Link eventKey="laser-cutting">
                      Lasersko Sečenje
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="btn">
                    <input type="radio" />
                    <Nav.Link eventKey="welding">
                      Zavarivanje
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="btn">
                    <input type="radio" />
                    <Nav.Link eventKey="painting">
                      Farbanje
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="btn">
                    <input type="radio" />
                    <Nav.Link eventKey="powder-coating">
                      Plastifikacija
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="btn">
                    <input type="radio" />
                    <Nav.Link eventKey="bending">
                      Savijanje
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="btn">
                    <input type="radio" />
                    <Nav.Link eventKey="manufacture-of-machine-assemblies">
                      Izrada Mašinskih Sklopova
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <Tab.Content>
                <Tab.Pane eventKey="all">                                    
                  <ResponsiveMasonry 
                    columnsCountBreakPoints={{
                      350: 1,
                      767: 2,
                      991: 3
                    }}
                  >
                    <Masonry gutter="20px">
                      {
                        Object.entries(images).map(([key, value]) => 
                          value.map((data) => (
                            <LightgalleryItem group="all" src={data.image}>
                              <div className="dz-box style-4" key={"all" + data.index}>
                                <div className="dz-media">
                                  <img src={data.image} alt="" />
                                </div>
                                <span className="view-btn lightimg">
                                  <i className="fas fa-plus" />
                                </span>
                              </div>
                            </LightgalleryItem>
                          ))
                        )
                      }
                    </Masonry>
                  </ResponsiveMasonry>
                </Tab.Pane>
                <Tab.Pane eventKey="laser-cutting">                                    
                  <ResponsiveMasonry 
                    columnsCountBreakPoints={{
                      350: 1,
                      767: 2, 
                      991: 3
                    }}
                  >
                    <Masonry gutter="20px">
                      {
                        images.laserCutting.map((data, index) => (
                          <LightgalleryItem group="laserCutting" src={data.image}>
                            <div className="dz-box style-4" key={data.image}>
                              <div className="dz-media">
                                <img src={data.image} alt="" />
                              </div>
                              <span className="view-btn lightimg">
                                <i className="fas fa-plus" />
                              </span>
                            </div>
                          </LightgalleryItem>
                        ))
                      }
                    </Masonry>
                  </ResponsiveMasonry>
                </Tab.Pane>
                <Tab.Pane eventKey="welding">                                    
                  <ResponsiveMasonry 
                    columnsCountBreakPoints={{
                      350: 1,
                      767: 2,
                      991: 3
                    }}
                  >
                    <Masonry gutter="20px">
                      {
                        images.welding.map((data) => (
                          <LightgalleryItem group="welding" src={data.image}>
                            <div className="dz-box style-4" key={data.image}>
                              <div className="dz-media">
                                  <img src={data.image} alt="" />
                              </div>
                              <span className="view-btn lightimg">
                                <i className="fas fa-plus" />
                              </span>
                            </div>
                          </LightgalleryItem>
                        ))
                      }
                    </Masonry>
                  </ResponsiveMasonry>
                </Tab.Pane>
                <Tab.Pane eventKey="painting">                                    
                  <ResponsiveMasonry 
                    columnsCountBreakPoints={{
                      350: 1,
                      767: 2,
                      991: 3
                    }}
                  >
                    <Masonry gutter="20px">
                      {
                        images.painting.map((data) => (
                          <LightgalleryItem group="painting" src={data.image}>
                            <div className="dz-box style-4" key={data.image}>
                              <div className="dz-media">
                                <img src={data.image} alt="" />
                              </div>
                              <span className="view-btn lightimg">
                                <i className="fas fa-plus" />
                              </span>
                            </div>
                          </LightgalleryItem>
                        ))
                      }
                    </Masonry>
                  </ResponsiveMasonry>
                </Tab.Pane>
                <Tab.Pane eventKey="powder-coating">                                    
                  <ResponsiveMasonry 
                    columnsCountBreakPoints={{
                      350: 1,
                      767: 2,
                      991: 3
                    }}
                  >
                    <Masonry gutter="20px">
                      {
                        images.powderCoating.map((data) => (
                          <LightgalleryItem group="powder-coating" src={data.image}>
                            <div className="dz-box style-4" key={data.image}>
                              <div className="dz-media">
                                <img src={data.image} alt="" />
                              </div>
                              <span className="view-btn lightimg">
                                <i className="fas fa-plus" />
                              </span>
                            </div>
                          </LightgalleryItem>
                        ))
                      }
                    </Masonry>
                  </ResponsiveMasonry>
                </Tab.Pane>
                <Tab.Pane eventKey="bending">                                    
                  <ResponsiveMasonry 
                    columnsCountBreakPoints={{
                      350: 1,
                      767: 2,
                      991: 3
                    }}
                  >
                    <Masonry gutter="20px">
                      {
                        images.bending.map((data) => (
                          <LightgalleryItem group="bending" src={data.image}>
                            <div className="dz-box style-4" key={data.image}>
                              <div className="dz-media">
                                <img src={data.image} alt="" />
                              </div>
                              <span className="view-btn lightimg">
                                <i className="fas fa-plus" />
                              </span>
                            </div>
                          </LightgalleryItem>
                        ))
                      }
                    </Masonry>
                  </ResponsiveMasonry>
                </Tab.Pane>
                <Tab.Pane eventKey="manufacture-of-machine-assemblies">                                    
                  <ResponsiveMasonry 
                    columnsCountBreakPoints={{
                      350: 1,
                      767: 2,
                      991: 3
                    }}
                  >
                    <Masonry gutter="20px">
                      {
                        images.manufactureOfMachineAssemblies.map((data) => (
                          <LightgalleryItem group="manufacture-of-machine-assemblies" src={data.image}>
                            <div className="dz-box style-4" key={data.image}>
                              <div className="dz-media">
                                <img src={data.image} alt="" />
                              </div>
                              <span className="view-btn lightimg">
                                <i className="fas fa-plus" />
                              </span>
                            </div>
                          </LightgalleryItem>
                        ))
                      }
                    </Masonry>
                  </ResponsiveMasonry>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section>

        <section className="section-full content-inner-5 section bg-white">
          <div className="container">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default Gallery;