import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Parallax } from "swiper/modules";

import VideoPopup from '../_App/VideoPopup';

//images
import background from "./../../assets/images/background/background_1.webp";
import banner from './../../assets/images/banner/banner_1.webp';

const MainBanner = () => {
  const paginationRef = React.useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <>
      <div className="page-content bg-white">
        <div className="main-slider5 overlay-black-middle" style={{ backgroundImage: "url(" + background + ")" }}>
          <div className="banner-inner">
            <div className="image-slider__pagination" ref={paginationRef}>
              <div className="image-slider__current">
                01
              </div>
              <div className="swiper-pagination-slider5 swiper-pagination" />
              <div className="image-slider__total">
                03
              </div>
            </div>

            <div className="row spno">
              <div className="col-lg-6 align-self-center">
                <div className="banner-content">
                  <h4 className="sub-title text-primary">
                    PAVIC DOO
                  </h4>
                  <h1 className="title">
                    Eksperti za Mašine i Mašinsku Proizvodnju
                  </h1>

                  <Swiper
                    className="swiper-container main-swiper5"
                    speed={1500}
                    parallax={true}
                    slidesPerView={1}
                    spaceBetween={0}
                    watchSlidesProgress={true}
                    autoplay={{
                      delay: 5000,
                      pauseOnMouseEnter: true
                    }}
                    pagination={{
                      clickable: true,
                      el: ".swiper-pagination-slider5",
                      type: "progressbar"
                    }}
                    modules={[Navigation, Pagination, Autoplay, Parallax]}
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                      swiper.params.navigation.prevEl = navigationPrevRef.current;
                      swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                  >
                    <SwiperSlide key={0}>
                      <p data-swiper-parallax="-500">
                        Kvalifikovani smo da od metalnih materijala proizvedemo sve što je potrebno našim kupcima. 
                        Garantujemo kvalitet i preciznost u radu, a naša moderna oprema omogućava nam da pružimo
                        visokokvalitetne usluge po konkurentnim cenama.
                      </p>
                    </SwiperSlide>

                    <SwiperSlide key={1}>
                      <p data-swiper-parallax="-500">
                        Naša stručna ekipa je tu da odgovori na sva Vaša pitanja i pruži Vam najbolju moguću uslugu. 
                        Stojimo Vam na raspolaganju svakim radnim danom od 8 do 17 časova i subotom od 8 do 13.
                      </p>
                    </SwiperSlide>

                    <SwiperSlide key={2}>
                      <p data-swiper-parallax="-500">
                        Aktivno pratimo najnovije trendove i implementiramo inovacije kako bismo obezbedili najbolju 
                        moguću uslugu. Zato kod nas uz samo nekoliko klikova možete dobiti ponudu i naručiti online, 
                        bez čekanja i gubljenja vremena.
                      </p>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="media-slider">
                  <VideoPopup classChange="popup-youtube play-btn5" />
                  <div className="dz-media">
                    <img src={banner} alt="Laser" width="780" height="1000" />
                  </div>
                </div>
              </div>
            </div>

            <div className="swiper-button">
              <div className="swiper-button-prev swiper-button-home-prev" ref={navigationPrevRef}>
                <i className="fas fa-arrow-left" />
              </div>
              <div className="swiper-button-next swiper-button-home-next" ref={navigationNextRef}>
                <i className="fas fa-arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainBanner;