import { BrowserRouter, Route, Routes } from "react-router-dom"

import ScrollToTop from "./components/_App/ScrollToTop";
import Header from "./components/_App/Header";
import Footer from "./components/_App/Footer";
import GoTop from "./components/_App/GoTop";
import DarkTheme from "./components/_App/DarkTheme";

import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Certificates from "./components/Certificates";
import References from "./components/References";
import Gallery from "./components/Gallery";
import ContactUs from "./components/ContactUs";
import NotFound from "./components/NotFound";

import LaserCutting from "./components/Services/LaserCutting";
import Welding from "./components/Services/Welding";
import Painting from "./components/Services/Painting";
import PowderCoating from "./components/Services/PowderCoating";
import Bending from "./components/Services/Bending";
import ManufactureOfMachineAssemblies from "./components/Services/ManufactureOfMachineAssemblies";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />

        <Header />

        <Routes>

          <Route exact path="/" element={ <Home /> } />

          <Route exact path="/about-us" element={ <AboutUs /> } />

          <Route exact path="/certificates" element={ <Certificates /> } />

          <Route exact path="/references" element={ <References /> } />

          <Route exact path="/gallery" element={ <Gallery /> } />

          <Route exact path="/contact-us" element={ <ContactUs /> } />

          <Route exact path="/laser-cutting" element={ <LaserCutting /> } />

          <Route exact path="/welding" element={ <Welding /> } />

          <Route exact path="/painting" element={ <Painting /> } />

          <Route exact path="/powder-coating" element={ <PowderCoating /> } />

          <Route exact path="/bending" element={ <Bending /> } />

          <Route exact path="/manufacture-of-machine-assemblies" element={ <ManufactureOfMachineAssemblies /> } />

          <Route path="*" element={ <NotFound /> }/>

        </Routes>

        <Footer />

        <GoTop />

        <DarkTheme />
      </BrowserRouter>
    </>
  );
}

export default App;