import React from "react";
import emailjs from "@emailjs/browser";

import PageTitle from "./_App/PageTitle";
import ClientsSlider from "./Sections/ClientsSlider";

//images
import background from "./../assets/images/background/background_6.webp";

const ContactUs = () => {
  const form = React.useRef();

  const sendEmail = (event) => {
    event.preventDefault();

    var templateParams = {
      Name: event.currentTarget.elements.Name.value,
      Email: event.currentTarget.elements.Email.value,
      Phone: event.currentTarget.elements.Phone.value,
      Message: event.currentTarget.elements.Message.value
    };

    emailjs.send("service_p45mq8v", "template_5m8a8ck", templateParams, "aXo1_SG1UNONpiGat")
      .then((_) => { window.confirm("Uspešno ste poslali poruku!"); });

    document.getElementById("form").reset();
  };

  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Kontakt" parentPage="Naslovna" />

        <section className="content-inner-2 pt-0">
          <div className="map-iframe">
            <iframe
              title="contact"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1428.5774776952917!2d19.910165375862157!3d44.26563039634479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4759edb87d624d1b%3A0x71c79635c1e5f84a!2sPavic%20Ltd.!5e0!3m2!1sen!2srs!4v1706343765326!5m2!1sen!2srs"
              style={{ border: "0", width: "100%", minHeight: "100%", marginBottom: "-8px" }}
              allowFullScreen
            />
          </div>
        </section>

        <section className="contact-wraper1" style={{ backgroundImage: "url(" + background + ")" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-info">
                  <div className="section-head text-white style-1">
                    <h3 className="title text-white">
                      Informacije
                    </h3>
                    <p>
                      Pošaljite nam upit ili nas pozovite. Kontaktiraćemo Vas u najkraćem mogućem roku.
                    </p>
                  </div>
                  <ul className="no-margin">
                    <li className="icon-bx-wraper text-white left m-b30">
                      <div className="icon-md">
                        <span className="icon-cell">
                          <i className="flaticon-phone" />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="dz-tilte text-white">
                          Telefon:
                        </h4>
                        <p className="font-18">
                          +381 14 542-008
                        </p>
                      </div>
                    </li>
                    <li className="icon-bx-wraper text-white left m-b30">
                      <div className="icon-md">
                        <span className="icon-cell">
                          <i className="flaticon-message" />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="dz-tilte text-white">
                          Email:
                        </h4>
                        <p className="font-18">
                          office@pavicdoo.rs
                        </p>
                      </div>
                    </li>
                    <li className="icon-bx-wraper text-white left m-b30">
                      <div className="icon-md">
                        <span className="icon-cell">
                          <i className="flaticon-clock" />
                        </span>
                      </div>
                      <div className="icon-content">
                        <h4 className="dz-tilte text-white">
                          Radno Vreme:
                        </h4>
                        <p className="font-18">
                          Ponedeljak - Petak 08:00 - 17:00
                          <br />
                          Subota 09:00 - 13:00
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-7 m-b40">
                <div className="contact-area1 m-r20 m-md-r0">
                  <div className="section-head style-1">
                    <h6 className="sub-title text-primary">
                      PIŠITE NAM
                    </h6>
                    <h3 className="title m-b20">
                      Očekujemo Vaš Kontakt
                    </h3>
                  </div>
                  <form id="form" className="dz-form dzForm" ref={form} onSubmit={sendEmail}>
                    <div className="input-group">
                      <input
                        id="Name"
                        name="Name"
                        type="text"
                        className="form-control"
                        placeholder="Ime i Prezime"
                        required="required"
                        autoComplete="name"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        id="Email"
                        name="Email"
                        type="email"
                        className="form-control"
                        placeholder="Email Adresa"
                        required="required"
                        autoComplete="email"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        id="Phone"
                        name="Phone"
                        type="tel"
                        className="form-control"
                        placeholder="Broj Telefona"
                        required="required"
                        autoComplete="tel"
                      />
                    </div>
                    <div className="input-group">
                      <textarea
                        id="Message"
                        name="Message"
                        className="form-control"
                        placeholder="Ostavite Poruku"
                        cols="30"
                        rows="5"
                      />
                    </div>
                    <div>
                      <button type="submit" className="btn w-100 btn-primary btn-border">
                        Pošalji
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-full content-inner-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;