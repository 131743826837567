import React from "react";
import CountUp from "react-countup";

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

//images
import background from "./../../assets/images/background/background_2.webp";
import services1 from './../../assets/images/services/services_1.png';
import services2 from './../../assets/images/services/services_2.png';
import services3 from './../../assets/images/services/services_3.png';
import services4 from './../../assets/images/services/services_4.png';
import services5 from './../../assets/images/services/services_5.png';
import services6 from './../../assets/images/services/services_6.png';

const Services = () => {
  const yearsOfExprience = new Date().getFullYear() - 1990;
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <>
      <section id="services" className="page-content bg-white">
        <div className="main-slider3" />

        <div className="content-inner-1 section" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "top" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                <div className="section-head style-1">
                  <h3 className="title m-b30">
                    Proizvodimo širok spektar metalnih proizvoda po meri.
                  </h3>

                  <div className="exp-row">
                    <h2 className="year counter">
                      <CountUp end={yearsOfExprience} duration={5} />
                    </h2>
                    <p>
                      GODINA <span>ISKUSTVA</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 m-b30 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
                <p className="m-b30">
                  Sa više od 30 godina postojanja i iskustva na tržištu, naša stručnost u mašinskoj proizvodnji
                  omogućava nam da ispunimo sve zahteve naših kupaca proizvodnjom širokog spektra metalnih proizvoda.
                  Oslanjajući se na naše bogato znanje i posvećenost vrhunskom kvalitetu, garantujemo da svaki
                  proizvod koji napusti naše pogone ispunjava najviše standarde kvaliteta.
                </p>
                <Link to="./about-us" className="btn btn-primary btn-border btn-border m-r10 m-b10">
                  SAZNAJTE VIŠE
                </Link>
              </div>
            </div>

            <Swiper
              className="swiper-container content-slider about-swiper slider-btn-1"
              speed={1500}
              parallax={true}
              slidesPerView={3}
              spaceBetween={0}
              loop={true}
              autoplay={{
                delay: 3500,
                pauseOnMouseEnter: true
              }}
              pagination={{
                type: "fraction"
              }}
              modules={[Navigation, Pagination, Autoplay]}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1
                },
                576: {
                  slidesPerView: 1
                },
                768: {
                  slidesPerView: 2
                },
                992: {
                  slidesPerView: 2
                },
                1200: {
                  slidesPerView: 3
                }
              }}
            >
              <SwiperSlide key={0}>
                <div className="content-box2 overlay-shine">
                  <div className="dz-info">
                    <h3 className="title">
                      Lasersko Sečenje
                    </h3>
                    <p>
                      Vršimo uslužno lasersko sečenje <b>lima</b> na CO<sub>2</sub> i fiber laser mašinama, kao i lasersko sečenje <b>cevi</b> sa velikom preciznošću i brzinom sečenja.
                    </p>
                  </div>
                  <div className="dz-media m-b30">
                    <img src={services1} alt="Lasers Cutting" width="450" height="195" />
                  </div>
                  <div className="dz-bottom">
                    <Link to="/laser-cutting" className="btn-link">
                      DETALJNIJE
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide key={1}>
                <div className="content-box2 overlay-shine">
                  <div className="dz-info">
                    <h3 className="title">
                      Zavarivanje
                    </h3>
                    <p>
                      Profesionalno pružamo sve usluge zavarivanja, koje obavljaju naši sertifikovani zavarivači sa dugogodišnjim iskustvom i stručnošću. 
                    </p>
                  </div>
                  <div className="dz-media m-b30">
                    <img src={services2} alt="Welding" width="450" height="195" />
                  </div>
                  <div className="dz-bottom">
                    <Link to="/welding" className="btn-link">
                      DETALJNIJE
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide key={2}>
                <div className="content-box2 overlay-shine">
                  <div className="dz-info">
                    <h3 className="title">
                      Farbanje
                    </h3>
                    <p>
                      Uslugu farbanja vršimo u našim komorama najkvalitetnijim bojama prema zahtevu kupca, osiguravajući vrhunsku završnu obradu.
                    </p>
                  </div>
                  <div className="dz-media m-b30">
                    <img src={services3} alt="Painting" width="450" height="195" />
                  </div>
                  <div className="dz-bottom">
                    <Link to="/painting" className="btn-link">
                      DETALJNIJE
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide key={3}>
                <div className="content-box2 overlay-shine">
                  <div className="dz-info">
                    <h3 className="title">
                      Plastifikacija
                    </h3>
                    <p>
                      Procesom plastifikacije postižemo visok nivo estetike, postojanost boje i zaštitu od korozije i oštećenja, povećavajući dugovečnost proizvoda.
                    </p>
                  </div>
                  <div className="dz-media m-b30">
                    <img src={services4} alt="Powder Coating" width="450" height="195" />
                  </div>
                  <div className="dz-bottom">
                    <Link to="/powder-coating" className="btn-link">
                      DETALJNIJE
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide key={4}>
                <div className="content-box2 overlay-shine">
                  <div className="dz-info">
                    <h3 className="title">
                      Savijanje
                    </h3>
                    <p>
                      Nudimo uslugu savijanja limova apkant presama snage do 200 t, kao i uslužno rolovanje trovaljkom, pružajući precizne i kvalitetne rezultate.
                    </p>
                  </div>
                  <div className="dz-media m-b30">
                    <img src={services5} alt="Bending" width="450" height="195" />
                  </div>
                  <div className="dz-bottom">
                    <Link to="/bending" className="btn-link">
                      DETALJNIJE
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide key={5}>
                <div className="content-box2 overlay-shine">
                  <div className="dz-info">
                    <h3 className="title">
                      Izrada Mašinskih Sklopova
                    </h3>
                    <p>
                      Za naše kupce izrađujemo i montiramo mašinske sklopove od početka do kraja, osiguravajući kvalitet i pouzdanost u svakom koraku procesa.
                    </p>
                  </div>
                  <div className="dz-media m-b30">
                    <img src={services6} alt="Manufacture of Machine Assemblies" width="450" height="195" />
                  </div>
                  <div className="dz-bottom">
                    <Link to="/manufacture-of-machine-assemblies" className="btn-link">
                      DETALJNIJE
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>

              <div className="swiper-button">
                <div className="button-prev swiper-button-prev4" ref={navigationPrevRef} >
                  <i className="las la-arrow-left" />
                </div>
                <div className="button-next swiper-button-next4" ref={navigationNextRef}>
                  <i className="las la-arrow-right" />
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;