import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

//Images
import client1 from './../../assets/images/client/client_1.png';
import client2 from './../../assets/images/client/client_2.png';
import client3 from './../../assets/images/client/client_3.png';
import client4 from './../../assets/images/client/client_4.png';
import client5 from './../../assets/images/client/client_5.png';
import client6 from './../../assets/images/client/client_6.png';
import client7 from './../../assets/images/client/client_7.png';
import client8 from './../../assets/images/client/client_8.png';
import client9 from './../../assets/images/client/client_9.png';
import client10 from './../../assets/images/client/client_10.png';
import client11 from './../../assets/images/client/client_11.png';
import client12 from './../../assets/images/client/client_12.png';
import client13 from './../../assets/images/client/client_13.png';
import client14 from './../../assets/images/client/client_14.png';
import client15 from './../../assets/images/client/client_15.png';

const clientData = [
  { image: client1, name: "bystronic", url: "https://www.bystronic.com/" },
  { image: client2, name: "schinko", url: "https://www.shinko-jp.biz/" },
  { image: client3, name: "amr", url: "https://www.a-m-r.fr/" },
  { image: client4, name: "krusik", url: "https://www.krusik.rs/" },
  { image: client5, name: "vossloh", url: "https://www.vossloh.com/" },
  { image: client6, name: "gelso", url: "https://www.gelso.ch/" },
  { image: client7, name: "ultrafilter", url: "http://www.ultrafilter.ag/" },
  { image: client8, name: "elbi", url: "http://www.elbi.co.rs/" },
  { image: client9, name: "serbian armed forces", url: "https://www.vs.rs/" },
  { image: client10, name: "sklářské stroje znojmo", url: "https://www.sszn.cz/" },
  { image: client11, name: "evoworld", url: "https://www.evogroup.at/" },
  { image: client12, name: "tesoma", url: "https://tesoma.de/" },
  { image: client13, name: "springer", url: "https://www.springer.eu/" },
  { image: client14, name: "steamtec", url: "https://www.steamtec.at/" },
  { image: client15, name: "comet sud", url: "http://www.cometsud.it/" }
];

const ClientsSlider = () => {

  return (
    <>
      <section>
        <div className="content-inner-6">
          <div className="container">
            <Swiper
              className="swiper-container clients-swiper"
              speed={1500}
              parallax={true}
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 300,
                pauseOnMouseEnter: true
              }}
              modules={[Autoplay]}
              breakpoints={{
                0: {
                  slidesPerView: 3
                },
                576: {
                  slidesPerView: 4
                },
                768: {
                  slidesPerView: 5
                },
                992: {
                  slidesPerView: 6
                },
                1200: {
                  slidesPerView: 8
                }
              }}
            >
              {clientData.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="clients-client aos-item">
                    <a href={data.url} target="_blank" rel="noreferrer">
                      <img className="client-main" src={data.image} alt={data.name} width="199" height="65" />
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientsSlider;