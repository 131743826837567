import React from "react";

import PageTitle from "./_App/PageTitle";
import ClientsSlider from "./Sections/ClientsSlider";

//images
import background from "./../assets/images/background/background_8.webp";
import banner from './../assets/images/banner/banner_4.webp';

const AboutUs = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="O Nama" parentPage="Naslovna" />

        <section className="content-inner-1 section overlay-gadient-white" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                <div className="section-head style-1">
                  <h6 className="sub-title letter-s5">
                    PAVIC DOO
                  </h6>
                  <h3 className="title m-b30">
                    Srpska Firma sa Pogonom u Valjevu
                  </h3>
                  <p className="m-b30">
                    Preduzeće Pavic je porodično preduzeće koje je nastalo iz zanatske radnje osnovane 1980. godine.
                    Preduzeće raspolaže sa tri hale i magacinksim prostorom površine 3000 metara kvadratinih.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 m-b30 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
                <img src={banner} alt="About Us" width="595" height="340" />
              </div>
            </div>
          </div>

          <div className="container space-between" style={{ marginBottom: "100px" }}>
            <div className="row ">
              <div className="col-md-6">
                <blockquote className="blockquote">
                  <div className="quote-info">
                    <h4>
                      NAŠ ISTORIJAT
                    </h4>
                  </div>
                  <p>
                    Firma "Pavić" d.o.o. je srpska firma sa proizvodnim pogonom u Valjevu, osnovana 1980. godine. 
                    Preduzeće "Pavić" je porodično preduzeće koje je nastalo iz zanatske radnje osnovane iste godine. 
                    Poslovne principe ostvarujemo kroz kvalitet proizvoda i usluga koje pružamo, kao i usavršavanje 
                    zaposlenih. Uspešno poslujemo i gradimo partnerske odnose sa kompanijama u Evropskoj Uniji, 
                    Švajcarskoj, kao i na drugim inostranim tržištima, uz istovremeni fokus na srpsko tržište.
                  </p>
                </blockquote>
              </div>
              <div className="col-md-6">
                <blockquote className="blockquote">
                  <div className="quote-info">
                    <h4>
                      NAŠA MISIJA
                    </h4>
                  </div>
                  <p>
                    Misija firme "Pavić" je da proizvodi i usluge zadovolje želje kupaca, te da tehnologija koja se 
                    koristi u proizvodnji osigura konkurentnost proizvoda i usluga na tržištu u pogledu troškova i 
                    kvaliteta. Nadalje, težimo da preduzetnički koncept određenog posla prenesemo na naše zaposlene, 
                    pružajući im mogućnost za usvajanje novih veština i unapređenje njihovih sposobnosti, jer verujemo 
                    da je njihovo zadovoljstvo i uspeh temelj našeg rasta i prosperiteta.
                  </p>
                </blockquote>
              </div>
              <div className="col-md-6">
                <blockquote className="blockquote">
                  <div className="quote-info">
                    <h4>
                      NAŠA OBAVEZA
                    </h4>
                  </div>
                  <p>
                    Uvereni smo da su naši zaposleni ključna snaga naše firme. Nastojimo privući i zadržati 
                    najtalentovanije stručnjake, pružajući im optimalne radne uslove. U firmi "Pavić" stvaramo 
                    stimulativno radno okruženje koje podstiče pojedinca da preuzme inicijativu, bude kreativan 
                    i pruži svoj maksimum. Kroz obimne programe treninga i edukacija, aktivno podstičemo kontinuirani 
                    razvoj karijere naših zaposlenih, omogućavajući im da rastu zajedno sa firmom.
                  </p>
                </blockquote>
              </div>
              <div className="col-md-6">
                <blockquote className="blockquote">
                  <div className="quote-info">
                    <h4>
                      NAŠA VIZIJA
                    </h4>
                  </div>
                  <p>
                    Naša vizija je da postanemo prepoznatljivi po svojim vrednostima kako kod naših 
                    klijenata, poslovnih partnera, tako i kod naših zaposlenih. Težimo najvećim dostignućima u svakom 
                    segmentu poslovanja, odnosimo se s integritetom u svakom poslovnom poduhvatu, jer verujemo da je 
                    to temelj poverenja koje gradimo. Izvrsnost ono što nas čini najboljim u onome što radimo. 
                    Konstantno unapređujemo tržište kroz inovativne pristupe i rešenja, uvek korak ispred konkurencije.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>

          <div className="container">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;