import React from "react";

import { Link } from "react-router-dom";

//images
import logo from "./../../assets/images/logo.png";
import logoWhite from "./../../assets/images/logo white.png";

const Header = () => {
  const [headerFix, setheaderFix] = React.useState(false);
  const [openToggleBtn, setOpenToggleBtn] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 130);
    });
  }, []);

  return (
    <>
      <header className="site-header mo-left header style-5">
        <div className="top-bar">
          <div className="container">
            <div className="dz-topbar-inner d-flex justify-content-between align-items-center">
              <div className="dz-topbar-left">
                <ul>
                  <li>
                    <a href="https://maps.app.goo.gl/wgGdtnizijFarfYU8" target="_blank" rel="noreferrer">
                      <i className="fas fa-map-marker-alt" />
                      Suvoborska 121/b, Valjevo, Srbija
                    </a>
                  </li>
                  <li>
                    <a href="mailto:office@pavicdoo.rs">
                      <i className="fas fa-envelope" />
                      office@pavicdoo.rs
                    </a>
                  </li>
                </ul>
              </div>

              <div className="dz-topbar-right">
                <ul className="dz-social">
                  <li>
                    <a className="fab fa-facebook-f" href="https://www.facebook.com/Pavić-doo-100071253159751" aria-label="Posetite našu Facebook stranicu." target="_blank" rel="noreferrer" />
                  </li>
                  <li>
                    <a className="fab fa-linkedin" href="https://www.linkedin.com/in/pavic-doo" aria-label="Posetite našu LinkedIn stranicu." target="_blank" rel="noreferrer" />
                  </li>
                  <li>
                    <a className="fab fa-youtube" href="https://www.youtube.com/@pavicdoo1829" aria-label="Posetite naš YouTube kanal." target="_blank" rel="noreferrer" />
                  </li>
                  <li>
                    <a className="fab fa-google" href="https://maps.app.goo.gl/wgGdtnizijFarfYU8" aria-label="Posetite našu Google stranicu." target="_blank" rel="noreferrer" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="middle-bar bg-white">
          <div className="container">
            <div className="middle-area">
              <div className="logo-header logo-dark">
                <Link to="/">
                  <img src={logo} alt="logo" width="180" height="64" />
                </Link>
              </div>
              <div className="logo-header logo-white">
                <Link to="#">
                  <img src={logoWhite} alt="logo" width="180" height="64" />
                </Link>
              </div>

              <div className="extra-nav">
                <div className="service-info">
                  <span>OČEKUJEMO VAŠ POZIV</span>
                  <h4 className="title">
                    <a href="tel:+38114542008">
                      (+381) 14 542-008
                    </a>
                  </h4>
                </div>
                <a className="search-link" id="quik-search-btn" href="tel:+38114542008">
                  <i className="fas fa-phone" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""}`}>
          <div className="main-bar clearfix">
            <div className="container clearfix">
              <div className="logo-header mostion logo-white d-md-block d-lg-none">
                <Link to="/">
                  <img src={logoWhite} alt="logo" width="180" height="64" />
                </Link>
              </div>

              <button
                className={`navbar-toggler navicon justify-content-end ${openToggleBtn ? "open" : "collapsed"}`}
                onClick={() => setOpenToggleBtn(!openToggleBtn)}
              >
                <span />
                <span />
                <span />
              </button>

              <div className="extra-nav">
                <div className="extra-cell">
                  <a className="btn btn-primary d-sm-inline-flex d-none btn-border btn-border-white m-r10 m-b10" href="https://pavic.hefeos.com/login">
                    ZATRAŽITE PONUDU
                  </a>
                </div>
              </div>

              <div className={`header-nav navbar-collapse justify-content-start ${openToggleBtn ? "collapse show" : "collapse"}`}>
                <div className="logo-header">
                  <Link to="/">
                    <img src={logo} alt="logo" width="180" height="64" />
                  </Link>
                </div>

                <ul className="nav navbar-nav navbar navbar-left">
                  <li>
                    <Link to="/">
                      <span>
                        Naslovna
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us">
                      <span>
                        O nama
                      </span>
                    </Link>
                  </li>
                  <li
                    className={`sub-menu-down ${(openMenu ? "open" : "")}`}
                    onClick={() => setOpenMenu(!openMenu)}
                  >
                    <Link>
                      <span>
                        Usluge
                      </span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/laser-cutting">
                          Lasersko Sečenje
                        </Link>
                      </li>
                      <li>
                        <Link to="/welding">
                          Zavarivanje
                        </Link>
                      </li>
                      <li>
                        <Link to="/painting">
                          Farbanje
                        </Link>
                      </li>
                      <li>
                        <Link to="/powder-coating">
                          Plastifikacija
                        </Link>
                      </li>
                      <li>
                        <Link to="/bending">
                          Savijanje
                        </Link>
                      </li>
                      <li>
                        <Link to="/manufacture-of-machine-assemblies">
                          Izrada Mašinskih Sklopova
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/certificates">
                      <span>
                        Sertifikati
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/references">
                      <span>
                        Reference
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery">
                      <span>
                        Galerija
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">
                      <span>
                        Kontakt
                      </span>
                    </Link>
                  </li>
                </ul>

                <div className="dz-social-icon">
                  <ul>
                    <li>
                      <a className="fab fa-facebook-f" href="https://www.facebook.com/Pavić-doo-100071253159751" aria-label="Posetite našu Facebook stranicu." target="_blank" rel="noreferrer" />
                    </li>
                    <li>
                      <a className="fab fa-linkedin" href="https://www.linkedin.com/in/pavic-doo" aria-label="Posetite našu LinkedIn stranicu." target="_blank" rel="noreferrer" />
                    </li>
                    <li>
                      <a className="fab fa-youtube" href="https://www.youtube.com/@pavicdoo1829" aria-label="Posetite naš YouTube kanal." target="_blank" rel="noreferrer" />
                    </li>
                    <li>
                      <a className="fab fa-google" href="https://maps.app.goo.gl/wgGdtnizijFarfYU8" aria-label="Posetite našu Google stranicu." target="_blank" rel="noreferrer" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;