import React from "react";

import PageTitle from "../_App/PageTitle";
import ClientsSlider from "../Sections/ClientsSlider";

//images
import background from "./../../assets/images/background/background_2.webp";
import banner from "../../assets/images/gallery/manufacture_of_machine_assemblies/manufacture_of_machine_assemblies_1.webp";

const ManufactureOfMachineAssemblies = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Izrada Mašinskih Sklopova" parentPage="Naslovna" />

        <section className="content-inner-1 section" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "top" }}>
          <div className="container">
            <div className="row about-style14">
              <div className="col-lg-6 m-b30">
                <div className="dz-media overlay-white-light">
                  <img src={banner} alt="Manufacture of Machine Assemblies" width="973" height="912" />
                </div>
              </div>
              <div className="col-lg-6 m-b30 align-self-center">
                <div className="about-content">
                  <div className="section-head style-1">
                    <h2 className="title m-b20">
                      Izrada Mašinskih Sklopova
                    </h2>
                  </div>
                </div>
              </div>
              <div className="info">
                <p className="m-b30">
                  Izrada mašinskih sklopova je još jedna usluga koju preduzeće Pavić ima u svojoj ponudi. Uz najkvalitetniju opremu i znanje, kao i iskustvo našeg stručnog 
                  tima i podizvođača, možemo da odgovorimo na većinu zahteva naših kupaca. Ponosimo se time što pružamo sveobuhvatna rešenja koja zadovoljavaju najviše 
                  standarde kvaliteta i pouzdanosti.
                </p>
              </div>
            </div>
          </div>

          <div className="container content-inner-2">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default ManufactureOfMachineAssemblies;