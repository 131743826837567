import React from "react";
import ModalVideo from 'react-modal-video';

import { Link } from "react-router-dom";

const VideoPopup = ({classChange}) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <React.Fragment>
        <ModalVideo 
          channel='youtube'
          videoId="fuXvK0s4Xx0"
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        />
        <Link to="#" className={classChange} onClick={()=> setOpen(true)} >
          <i className="fa fa-play" />
        </Link>
    </React.Fragment>
  );
};

export default VideoPopup;