import React from "react";

import PageTitle from "../_App/PageTitle";
import ClientsSlider from "../Sections/ClientsSlider";

//images
import background from "./../../assets/images/background/background_2.webp";
import banner from "../../assets/images/gallery/bending/bending_1.webp";

const Bending = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Savijanje" parentPage="Naslovna" />

        <section className="content-inner-1 section" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "top" }}>
          <div className="container">
            <div className="row about-style14">
              <div className="col-lg-6 m-b30">
                <div className="dz-media overlay-white-light">
                  <img src={banner} alt="Bending" width="1397" height="1079" />
                </div>
              </div>
              <div className="col-lg-6 m-b30 align-self-center">
                <div className="about-content">
                  <div className="section-head style-1">
                    <h2 className="title m-b20">
                      Savijanje
                    </h2>
                  </div>
                  <div className="info">
                    <p className="m-b30">
                      Naše preduzeće raspolaže vrhunskom opremom za savijanje metala, uključujući SENFENG presu za savijanje kapaciteta <b>1300 KN</b> (<b>130t</b>) 
                      i dužine <b>3.2 m</b>, model BDE13032 iz 2023. godine. Takođe, posedujemo AMADA Promecam presu za savijanje kapaciteta <b>200 t</b> i 
                      dužine <b>4 m</b>, kao i AMADA Promecam presu kapaciteta <b>50 t</b> i dužine <b>2 m</b>. Ova oprema omogućava nam da pružimo precizne i pouzdane 
                      usluge savijanja, zadovoljavajući potrebe naših klijenata.
                    </p>
                  </div>
                </div>
              </div>
              <div className="info">
                <p className="m-b30">
                  Savijanje je još jedna od usluga koju preduzeće Pavić nudi. Uz najkvalitetniju opremu, dugogodišnje iskustvo i stručni tim, prepoznatljivi smo na
                  tržištu u ovoj oblasti.
                </p>
              </div>
            </div>
          </div>

          <div className="container content-inner-2">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default Bending;