import React from "react";

import { Link } from "react-router-dom";

//images
import logoWhite from "./../../assets/images/logo white.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="site-footer style-5" id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-sm-8 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                <div className="widget widget_about">
                  <div className="footer-logo logo-white">
                    <a href="/">
                      <img src={logoWhite} alt="Pavic White Logo" width="180" height="64" />
                    </a>
                  </div>
                  <div className="widget widget_getintuch">
                    <ul>
                      <li>
                        <i className="flaticon-placeholder" />
                        <span>Adresa:</span>
                        <br />
                        Suvoborska 121/b, 14000 Valjevo
                      </li>
                      <li>
                        <a href="tel:+38114542008">
                          <i className="flaticon-call" />
                          <span>Telefon:</span>
                          <br />
                          +381 14 542-008
                        </a>
                      </li>
                      <li>
                        <a href="mailto:office@pavicdoo.rs">
                          <i className="flaticon-chat-1" />
                          <span>Email:</span>
                          <br />
                          office@pavicdoo.rs
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dz-social-icon">
                    <ul>
                      <li>
                        <a className="fab fa-facebook-f" href="https://www.facebook.com/Pavić-doo-100071253159751" aria-label="Posetite našu Facebook stranicu." target="_blank" rel="noreferrer" />
                      </li>
                      <li>
                        <a className="fab fa-linkedin" href="https://www.linkedin.com/in/pavic-doo" aria-label="Posetite našu LinkedIn stranicu." target="_blank" rel="noreferrer" />
                      </li>
                      <li>
                        <a className="fab fa-youtube" href="https://www.youtube.com/@pavicdoo1829" aria-label="Posetite naš YouTube kanal." target="_blank" rel="noreferrer" />
                      </li>
                      <li>
                        <a className="fab fa-google" href="https://maps.app.goo.gl/wgGdtnizijFarfYU8" aria-label="Posetite našu Google stranicu." target="_blank" rel="noreferrer" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-lg-2 col-sm-4 col-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
                <div className="widget widget_services">
                  <h4 className="footer-title">
                    Kompanija
                  </h4>
                  <ul>
                    <li>
                      <Link to="/">
                        Naslovna
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us">
                        O Nama
                      </Link>
                    </li>
                    <li>
                      <Link to="/certificates">
                        Sertifikati
                      </Link>
                    </li>
                    <li>
                      <Link to="/references">
                        Reference
                      </Link>
                    </li>
                    <li>
                      <Link to="/gallery">
                        Galerija
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">
                        Kontakt
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-2 col-lg-2 col-sm-4 col-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="600">
                <div className="widget widget_services">
                  <h4 className="footer-title">
                    Usluge
                  </h4>
                  <ul>
                    <li>
                      <Link to="/laser-cutting">
                        Lasersko Sečenje
                      </Link>
                    </li>
                    <li>
                      <Link to="/welding">
                        Zavarivanje
                      </Link>
                    </li>
                    <li>
                      <Link to="/painting">
                        Farbanje
                      </Link>
                    </li>
                    <li>
                      <Link to="/powder-coating">
                        Plastifikacija
                      </Link>
                    </li>
                    <li>
                      <Link to="/bending">
                        Savijanje
                      </Link>
                    </li>
                    <li>
                      <Link to="/manufacture-of-machine-assemblies">
                        Izrada Mašinskih Sklopova
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-sm-8 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                <h4 className="footer-title">
                  Radno Vreme
                </h4>
                <div className="widget_opentime" data-aos="fade-up" data-aos-delay="800" data-aos-duration="600">
                  <ul>
                    <li>
                      <i className="flaticon-clock" />
                      &nbsp;
                      Ponedeljak - Petak:
                      <p className="right">
                        08:00 - 17:00
                      </p>
                    </li>
                    <li>
                      <i className="flaticon-clock" />
                      &nbsp;
                      Subota:
                      <p className="right">
                        08:00 - 13:00
                      </p>
                    </li>
                    <li>
                      <i className="flaticon-clock" />
                      &nbsp;
                      Nedelja:
                      <p className="right">
                        Neradan Dan
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <span className="copyright-text">
              Copyright &copy; {currentYear} <a href="https://nubik.io/" target="_blank" rel="noreferrer" >NUBIK</a>. All rights reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;