import React from "react";

//images
import background from "./../../assets/images/background/background_5.webp";
import team1 from './../../assets/images/team/team_1.webp';
import team2 from './../../assets/images/team/team_2.webp';

const OurTeam = () => {
  return (
    <>
      <section id="team" className="page-content bg-white overlay-black-dark" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "center" }}>
        <div className="content-inner">
          <div className="container">
            <div className="section-head text-center style-1">
              <h5 className="text-primary sub-title">
                TIM
              </h5>
              <h3 className="text-white title">
                Tim Koji Promoviše Postignuće, Uspeh, Individualan Pristup i Zajedništvo
              </h3>
            </div>
            <div className="row">
              <div className="col-lg-6 aos-item">
                <div className="dz-card style-3 overlay-shine m-b30">
                  <div className="dz-media">
                    <img src={team1} alt="Proizvodni Menadžment" width="1024" height="683" />
                  </div>
                  <div className="dz-info">
                    <h5 className="dz-title text-center text-white">
                      PROIZVODNI MENADŽMENT
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 aos-item">
                <div className="dz-card style-3 overlay-shine m-b30">
                  <div className="dz-media">
                    <img src={team2} alt="Inženjering Tim" width="1024" height="683" />
                  </div>
                  <div className="dz-info">
                    <h5 className="dz-title text-center text-white">
                      INŽENJERING TIM
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurTeam;