import React from "react";

import { Link } from "react-router-dom";

import ClientsSlider from "./ClientsSlider";

//Images
import background from "./../../assets/images/background/background_4.webp";
import banner from './../../assets/images/banner/banner_3.webp';

const Clients = () => {

  return (
    <>
      <div className="page-content bg-white" style={{ backgroundImage: "url(" + background + ")", backgroundSize: "cover", backgroundPosition: "center" }}>
        <div className="content-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 m-b30">
                  <div className="about-thumb p-r15 p-md-r0">
                    <img className="w-100 m-b10" src={banner} alt="Our Clients" width="570" height="655" />
                  </div>
              </div>
              <div className="col-lg-7 m-b30 align-self-center">
                <div className="about-content">
                  <div className="section-head style-1 aos-item">
                    <h5 className="sub-title text-primary">
                      AUTSORSING
                    </h5>
                    <h2 className="title m-b20">
                      Zašto Sarađivati sa Nama?
                    </h2>
                  </div>
                  <div className="row m-b10">
                    <div className="col-md-8 m-b30 aos-item wow fadeInUp" data-wow-delay="0.01s" data-wow-duration="1.3s">
                      <p>
                        Naša firma uspešno posluje već dugi niz godina, gradeći snažne partnerske odnose sa kompanijama 
                        u Evropskoj Uniji i Švajcarskoj. Kroz našu posvećenost kvalitetu i stručnost, pružamo autsorsing 
                        rešenja koja odgovaraju potrebama naših stranih partnera. Naš tim stručnjaka nije samo najbolji 
                        u poslu, već i neprekidno radi na proširenju našeg poslovanja na druga inostrana tržišta. 
                        Sa fokusom na strane kompanije, usmeravamo svoje poslovne planove ka izgradnji dugoročnih i 
                        održivih odnosa širom sveta.
                      </p>
                      <Link to="./references" className="btn btn-primary btn-border btn-border m-r10 m-b10">
                        SAZNAJTE VIŠE
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <ul className="list-square-box">
                        <li className="aos-item wow fadeInUp" data-wow-delay="0.01s" data-wow-duration="1.3s">
                          <h4>       
                            Kvalitet
                          </h4>
                          <p>
                            Garancija vrhunskog kvaliteta proizvoda i usluga koje pružamo.
                          </p>
                        </li>
                        <li className="aos-item wow fadeInUp" data-wow-delay="0.01s" data-wow-duration="1.3s">
                          <h4>
                            Stručnost
                          </h4>
                          <p>
                            Angažujemo najbolje kadrove i pružamo im najbolje uslove za rad.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ClientsSlider />
      </div>
    </>
  );
};

export default Clients;