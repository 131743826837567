import React from "react";

import { Link } from "react-router-dom";

import PageTitle from "./_App/PageTitle";
import ClientsSlider from "./Sections/ClientsSlider";

//images
import certificate1 from "./../assets/images/certificates/ISO 3834.png";
import certificate2 from "./../assets/images/certificates/ISO 9001.png";
import certificate3 from "./../assets/images/certificates/ISO 14001.png";
import certificate4 from "./../assets/images/certificates/ISO 45001.png";

//files
import pdf1 from "./../assets/pdfs/Podaci o Identifikaciji.pdf"
import pdf2 from "./../assets/pdfs/Politika Kvaliteta.pdf"
import pdf3 from "./../assets/pdfs/ISO 3834.pdf"
import pdf4 from "./../assets/pdfs/ISO 9001.pdf"
import pdf5 from "./../assets/pdfs/ISO 14001.pdf"
import pdf6 from "./../assets/pdfs/ISO 45001.pdf"

const Certificates = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Sertifikati" parentPage="Naslovna" />

        <section className="content-inner-1 section bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                <div className="section-head style-1">
                  <h6 className="sub-title letter-s5">
                    Kvalitet i Sertifikati
                  </h6>
                  <h3 className="title m-b30">
                    Kvalitet Kojim Odgovaramo na Vaše Zahteve
                  </h3>
                  <p className="m-b30">
                    Firma se s ponosom ističe posedovanjem sertifikata prema više ISO standarda, što potvrđuje našu 
                    posvećenost vrhunskom kvalitetu i izuzetnosti u različitim aspektima poslovanja. Svaki sertifikat 
                    predstavlja korak ka sigurnijem i kvalitetnijem iskustvu za naše klijente.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 aos-item" data-aos="zoom-out" data-aos-duration="800" data-aos-delay="800">
                <div className="icon-bx-wraper left style-3 m-b20">
                  <div className="icon-lg text-primary">
                    <span className="icon-cell">
                      <i className="flaticon-fingerprint" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h4 className="dz-title">
                      IDENTIFIKACIJA
                    </h4>
                    <Link to={pdf1} target="_blank" className="btn btn-primary btn-icon">
                      OTVORI
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="icon-bx-wraper left style-3 m-b20">
                  <div className="icon-lg text-primary">
                    <span className="icon-cell">
                      <i className="flaticon-star" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <h4 className="dz-title">
                      POLITIKA KVALITETA
                    </h4>
                    <Link to={pdf2} target="_blank" className="btn btn-primary btn-icon">
                      OTVORI
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-inner-1 section bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="content-box2 m-b30">
                  <div className="dz-media m-b30">
                    <img src={certificate2} alt="ISO 9001:2015" width="500" height="500" />
                  </div>
                  <div className="dz-info">
                    <h3 className="title">
                      ISO 9001:2015
                    </h3>
                    <p>
                      Standard za lasersko sečenje i proizvodnju metalnih konstrukcija, mašina i opreme.
                    </p>
                  </div>
                  <div className="dz-bottom">
                    <Link to={pdf4} target="_blank" className="btn-link">
                      OTVORI
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="content-box2 m-b30">
                  <div className="dz-media m-b30">
                    <img src={certificate3} alt="ISO 9001:2015" width="500" height="500" />
                  </div>
                  <div className="dz-info">
                    <h3 className="title">
                      ISO 14001:2015
                    </h3>
                    <p>
                      Standard za lasersko sečenje i proizvodnju metalnih konstrukcija, mašina i opreme.
                    </p>
                  </div>
                  <div className="dz-bottom">
                    <Link to={pdf5} target="_blank" className="btn-link">
                      OTVORI
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="content-box2 m-b30">
                  <div className="dz-media m-b30">
                    <img src={certificate4} alt="ISO 9001:2015" width="500" height="500" />
                  </div>
                  <div className="dz-info">
                    <h3 className="title">
                      ISO 45001:2018
                    </h3>
                    <p>
                      Standard za lasersko sečenje i proizvodnju metalnih konstrukcija, mašina i opreme.
                    </p>
                  </div>
                  <div className="dz-bottom">
                    <Link to={pdf6} target="_blank" className="btn-link">
                      OTVORI
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="content-box2 m-b30">
                  <div className="dz-media m-b30">
                    <img src={certificate1} alt="ISO 9001:2015" width="500" height="500" />
                  </div>
                  <div className="dz-info">
                    <h3 className="title">
                      ISO 3834-2:2021
                    </h3>
                    <p>
                      Standard za zavarivanje i proizvodnju metalnih konstrukcija, mašina i opreme.
                    </p>
                  </div>
                  <div className="dz-bottom">
                    <Link to={pdf3} target="_blank" className="btn-link">
                      OTVORI
                      <i className="fas fa-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-full content-inner-5 section bg-gray">
          <div className="container">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default Certificates;