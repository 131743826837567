import React from "react";

const DarkTheme = () => {
  const handleThemeMode = () => {
    var elementClass = document.body.classList;

    if (elementClass.contains('layout-light')) {
      elementClass.remove('layout-light');
      elementClass.add('layout-dark');
    } else {
      elementClass.remove('layout-dark');
      elementClass.add('layout-light');
    }
  };

  return (
    <>
      <div className="dz-mode" onClick={() => handleThemeMode()}>
        <i className="fas fa-sun" />
        <i className="fas fa-moon" />
      </div>
    </>
  );
};

export default DarkTheme;