import React from "react";

import { Link } from "react-router-dom";

import PageTitle from "./_App/PageTitle";
import ClientsSlider from "./Sections/ClientsSlider";

//images
import background1 from "./../assets/images/background/background_10.webp";
import background2 from "./../assets/images/background/background_6.webp";
import background3 from "./../assets/images/background/background_11.webp";
import banner from './../assets/images/banner/banner_6.webp';

const References = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle title="Reference" parentPage="Naslovna" />

        <section className="content-inner-1 section bg-gray" style={{ backgroundImage: "url(" + background1 + ")", backgroundSize: "cover", backgroundPosition: "center" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
                <div className="section-head style-1">
                  <h6 className="sub-title letter-s5">
                    REFERENCE I AUTSORSING
                  </h6>
                  <h3 className="title m-b30">
                    Rešenja za Strane Kompanije
                  </h3>
                  <p className="m-b30">
                    Višestruko iskustvo koje je preneto sa generacije na generaciju udruženo sa novom tehnologijom i 
                    obučenim i motivisanim kadrom omogućilo je uspostavljanje uspešne saradnje sa velikim brojem kupaca 
                    na domaćeim i inostranom tržištu. Preduzeće je ostvarilo uspešnu saradnju sa nekoliko velikih 
                    kompanija iz zemalja Evropske Unije i Švajcarske na razvoju i konstrukcijama mašina koje se koriste 
                    u različitim granama industrije.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 m-b30 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
                <img src={banner} alt="References" width="700" height="454" />
              </div>
            </div>
          </div>
        </section>

        <section className="content-inner-4 overlay-black-middle" style={{ backgroundImage: "url(" + background2 + ")", backgroundSize: "cover", backgroundPosition: "center" }}>
          <div className="container">
            <div className="section-head style-1 text-center mb-0">
              <h5 className="sub-title text-primary">
                AUTSORSING
              </h5>
              <h2 className="title m-b30 text-white">
                Ostvarite Benefite Autsorsing Mogućnosti
              </h2>
              <h4 className="title m-b50 text-white">
                STOJIMO VAM NA RASPOLAGANJU
              </h4>
              <Link to="../contact-us" className="btn btn-primary btn-border btn-border-white m-r10 m-b10">
                KONTAKTIRAJTE NAS
              </Link>
            </div>
          </div>
        </section>

        <section className="section-full content-inner-3 section bg-gray" style={{ backgroundImage: "url(" + background3 + ")", backgroundSize: "cover", backgroundPosition: "center" }}>
          <div className="container">
            <div className="section-head style-1 text-center mb-0">
              <h5 className="sub-title text-primary">
                PARTNERI
              </h5>
              <h3 className="title m-b50">
                Ponosni Smo na Saradnju sa Našim Poslovnim Partnerima
              </h3>
            </div>
          </div>
          <div className="container">
            <ClientsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default References;