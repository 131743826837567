import React from "react";

import MainBanner from "./Sections/MainBanner";
import Services from "./Sections/Services";
import RequestQuote from "./Sections/RequestQuote";
import Clients from "./Sections/Clients";
import OurTeam from "./Sections/OurTeam";
import Infrastructure from "./Sections/Infrastructure";

const Home = () => {
  return (
    <>
      <MainBanner />

      <Services />

      <RequestQuote />

      <Clients />

      <OurTeam />

      <Infrastructure />
    </>
  );
};

export default Home;